<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
<!--      <a-form-model-item label="用户id" prop="userId" >-->
<!--        <a-input v-model="form.userId" placeholder="请输入用户id" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="话题ID" prop="topicId" >-->
<!--        <a-input v-model="form.topicId" placeholder="请输入内容" type="textarea" allow-clear />-->
<!--      </a-form-model-item>-->
      <a-form-model-item v-show="formType == 3" label="内容" prop="content" >
<!--        <a-input v-model="form.content" />-->
        <a-input v-model="form.content" type="textarea" allow-clear />
      </a-form-model-item>
<!--      <a-form-model-item label="图片" prop="imgs" >-->
<!--        <a-input v-model="form.imgs" placeholder="请输入内容" type="textarea" allow-clear />-->
<!--      </a-form-model-item>-->
      <a-form-model-item v-show="formType == 3" label="视频地址" prop="fileUrl" >
        <a-input v-model="form.fileUrl" type="textarea" allow-clear />
      </a-form-model-item>
<!--      <a-form-model-item v-show="formType == 3" label="视频封面地址" prop="fileCover" >-->
<!--        <file-upload v-model="form.fileCover" type="image"></file-upload>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item v-show="formType == 3" label="浏览量" prop="browseCount" >-->
<!--        <a-input v-model="form.browseCount" placeholder="请输入浏览量" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item v-show="formType == 3" label="点赞量" prop="likeCount" >-->
<!--        <a-input v-model="form.likeCount" placeholder="请输入点赞量" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item v-show="formType == 3" label="收藏数" prop="collectNum" >-->
<!--        <a-input v-model="form.collectNum" placeholder="请输入收藏数" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item v-show="formType == 3" label="分享数" prop="shareNum" >-->
<!--        <a-input v-model="form.shareNum" placeholder="请输入分享数" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item v-show="formType == 3" label="区域名称" prop="cityName" >-->
<!--        <a-input v-model="form.cityName" readOnly />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="评论量" prop="commentCount" >-->
<!--        <a-input v-model="form.commentCount" placeholder="请输入评论量" />-->
<!--      </a-form-model-item>-->
      <a-form-model-item v-show="formType == 2" label="审核状态" prop="checkStatus" >
        <a-select placeholder="请选择审核状态" v-model="form.checkStatus">
          <a-select-option :key="0" :value="0">请选择</a-select-option>
          <a-select-option :key="1" :value="1">通过</a-select-option>
          <a-select-option :key="2" :value="2">拒绝</a-select-option>
<!--          <a-select-option v-for="(d, index) in checkStatusOptions" :key="index" :value="d.value" >{{ d.label }}</a-select-option>-->
        </a-select>
      </a-form-model-item>
<!--      <a-form-model-item v-show="form.checkStatus == 2" label="审核不通过原因" prop="checkNoPassReason" >-->
<!--        <a-input v-model="form.checkNoPassReason" placeholder="请输入内容" type="textarea" allow-clear />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item v-show="formType == 3" label="经度" prop="longitude" >-->
<!--        <a-input v-model="form.longitude" readOnly />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item v-show="formType == 3" label="纬度" prop="latitude" >-->
<!--        <a-input v-model="form.latitude" readOnly />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item v-show="formType == 3" label="分享短连接" prop="shortConnection" >-->
<!--        <a-input v-model="form.shortConnection" readOnly />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="是否推荐" prop="recommend" >-->
<!--        <a-select placeholder="请选择是否推荐" v-model="form.recommend">-->
<!--          <a-select-option v-for="(d, index) in recommendOptions" :key="index" :value="d.value" >{{ d.label }}</a-select-option>-->
<!--        </a-select>-->
<!--      </a-form-model-item>-->
      <a-form-model-item v-show="formType == 3" label="备注" prop="remark" >
        <a-input v-model="form.remark" readOnly />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button v-show="formType == 2" type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getPost, addPost, updatePost } from '@/api/biz/post'
import Editor from '@/components/Editor'

export default {
  name: 'CreateForm',
  props: {
    checkStatusOptions: {
      type: Array,
      required: true
    },
    recommendOptions: {
      type: Array,
      required: true
    }
  },
  components: {
    Editor
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        userId: null,

        topicId: null,

        content: null,

        imgs: null,

        fileUrl: null,

        fileCover: null,

        browseCount: null,

        likeCount: null,

        collectNum: null,

        shareNum: null,

        cityName: null,

        commentCount: null,

        checkStatus: null,

        checkNoPassReason: null,

        longitude: null,

        latitude: null,

        shortConnection: null,

        recommend: null,

        createTime: null,

        remark: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        userId: [
          { required: true, message: '用户id不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        userId: null,
        topicId: null,
        content: null,
        imgs: null,
        fileUrl: null,
        fileCover: null,
        browseCount: null,
        likeCount: null,
        collectNum: null,
        shareNum: null,
        cityName: null,
        commentCount: null,
        checkStatus: null,
        checkNoPassReason: null,
        longitude: null,
        latitude: null,
        shortConnection: null,
        recommend: null,
        createTime: null,
        remark: null
      }
      this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getPost({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    handleDetail (row, ids) {
      this.reset()
      this.formType = 3
      const id = row ? row.id : ids
      getPost({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '详情'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.form.checkStatus === 0) {
            this.$message.error("请选择审核结果")
            return false;
          }
          // else if (this.form.checkStatus === 2 && !this.form.checkNoPassReason) {
          //   this.$message.error("请输入拒绝原因")
          //   return false;
          // }

          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updatePost(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addPost(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
