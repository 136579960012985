import request from '@/utils/request'


// 查询创投Live列表
export function listPost(query) {
  return request({
    url: '/biz/post/list',
    method: 'get',
    params: query
  })
}

// 查询创投Live分页
export function pagePost(query) {
  return request({
    url: '/biz/post/page',
    method: 'get',
    params: query
  })
}

// 查询创投Live详细
export function getPost(data) {
  return request({
    url: '/biz/post/detail',
    method: 'get',
    params: data
  })
}

// 新增创投Live
export function addPost(data) {
  return request({
    url: '/biz/post/add',
    method: 'post',
    data: data
  })
}

// 修改创投Live
export function updatePost(data) {
  return request({
    url: '/biz/post/edit',
    method: 'post',
    data: data
  })
}

// 删除创投Live
export function delPost(data) {
  return request({
    url: '/biz/post/delete',
    method: 'post',
    data: data
  })
}
